.serviceHeader {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin-top: -15px;
    border-bottom: 1px solid #e0e0e0;
    > * {
        display: flex;
        width: 32%;
    }

    .name {
        padding-left: 30px;
        flex-flow: column nowrap;

        h6 {
            font-weight: bold;
            margin-bottom: 0px;
            color: #828282;
        }
        h4 {
            margin-top: 0px;
            font-weight: 300;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .seatingDetails {
        justify-content: center;
        > div {
            color: $brand-dark;
            display: flex;
            flex-flow: column nowrap;
            align-items: center;
            margin: 0px 0.75em;
            font-size: 12px;
            div:first-of-type {
                margin-bottom: 5px;
            }
            div:last-of-type {
                font-size: 1.3rem;
            }
        }
    }

    .actions {
        justify-content: flex-end;
        padding-right: 15px;
        > * {
            margin-bottom: 15px;
            align-self: center;
            margin-right: 10px;
            height: auto;
        }
        .status-text {
            margin-bottom: 25px;
            font-size: 18px;
        }
    }
}

@media screen and (max-width: 900px) {
    .serviceHeader {
        .name {
            padding-left: 15px;
            padding-right: 15px;
            width: 100%;
        }
        .seatingDetails {
            width: 100%;
            justify-content: center;
            padding: 0 10px;
        }
        .actions {
            padding: 15px;
            justify-content: center;
            width: 100%;
        }
    }
}
