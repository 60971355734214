.paginate {
    display: flex;
    height: 3rem;
    margin-top: 10px;
    align-items: center;
    user-select: none;
    > *:not(last-of-type) {
        margin-right: 4px;
    }

    a.active {
        background: $brand-main !important;
        color: white !important;
        border: 1px solid $brand-main !important;
    }
}
