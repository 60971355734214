.modal-container {
    top: 0 !important;
    left: 0 !important;
    position: fixed !important;
    z-index: 1000;
    width: 101% !important;
    height: 101% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
    overflow: hidden;
    margin: 0 !important;
    .modal {
        width: 30em;
        background: white;
        border: 1px solid #ccc;
        border-radius: 2px;
        box-shadow: $box-shadow;
        .modal-body {
            display: flex;
            flex-flow: column nowrap;
            align-items: center;
            padding: 2rem;
        }
    }
    &.fade-in {
        opacity: 1;
        transition: opacity linear 0.15s;
    }
    &.fade-out {
        opacity: 0;
        transition: opacity linear 0.15s;
    }

    &.large {
        align-items: flex-start;
        overflow-y: scroll;
        .modal {
            margin: 30px 0;
            width: 85%;
        }
    }
    &.medium {
        align-items: flex-start;
        overflow-y: scroll;
        .modal {
            margin: 30px 0;
            width: 55%;
        }
    }
}

@media screen and (max-width: 899px) {
    .modal-container {
        &.medium {
            align-items: flex-start;
            overflow-y: scroll;
            .modal {
                margin: 30px 0;
                width: 90%;
            }
        }
    }
}
