.login-create {
    display: flex;
    width: 100vw;
    min-height: 100vh;

    .container-width {
        width:80%
    }

    p.noAccountText {
        width: 80%;
        text-align: center;
        line-height: 1.5;
        font-size: 16px;
    }

    .btn.big {
        background: rgba(255, 255, 255, 0.10);
        border: 1px solid $brand-main;
        color: $brand-main;
        margin: auto;
        width: 150px;
    }
    
    .blue-back {
        background: $brand-main;
        .btn.big {
            border: 1px solid rgba(255, 255, 255, 0.5);
            color: white;
        }
        .body {
            color: white;
        }
        h3  {
            color: white;
        }
    }

    .third {
        width: 40%;
        .hidden-desktop {
            display: none;
        }
        .hidden-mobile {
            display: block;
        }
    }

    .vert-half-top {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }

    .has-brand {
        .vert-half-top {
            position: relative;
            top: 50%;
            transform: translateY(-100%);
        }
    }

    a.brand {
        padding: 10px;
        display: flex;
        align-items: center;
        font-size: 18px;
        color: white;
        img {
            height: 50px;
            margin-right: 10px;
        }
    }
    .body {
        text-align: center;
        width: 60%;
        margin: auto;
        color: $brand-main;
        display: flex;
        flex-direction: column;
        p {
            margin-bottom: 30px;
        }
    }

    h3  {
        color: $brand-main;
        font-weight: bold;
    }

    .twoThird {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        width: 60%;
        padding: 100px 0 30px 0;
        .hidden-desktop {
            display: none;
        }
        .hidden-mobile {
            display: block;
        }

        .brand {
            position: absolute;
            top: 0px;
            left: 0px;
        }
    }

    nav {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        margin-bottom: 15px;
        div {
            background: #828282;
            width: 10px;
            height: 10px;
            margin: 10px 5px;
            border-radius: 100%;
            &.active {
                background: $brand-accent;
            }
        }
    }
    .forms {
        width: 500px;
        .header {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            margin-bottom: 30px;
            justify-content: center;
            position: relative;
        }
        h4 {
        text-align: center;
            font-weight: 300;
            margin: 0px
        }
        
        .goBack {
            position: absolute;
            left: 0;
            color: $base-body-light;
            cursor: pointer;
        }
        .provider-icon {
            height: 1.3rem;
            margin-right: 10px;
            width: auto;
        }
        .provider-signin {
            justify-content: center;
        }
    }

    .login-break {
        font-weight: bold;
        text-align: center;
        display: flex;
        justify-content: center;
        .line {
            border-bottom: 1px solid #a9a9a9;
            width: 100%;
            position: absolute;
            top: 7px;
        }
        .or {
            color: #a9a9a9;
            position: relative;
            background: white;
            width: 40px;
        }
    }

    .url-prepend {
        position: absolute;
        right: 10px;
        top: 30px;
        color: gray;
    }

    .church-name-id {
        line-height: 1.3;
        span.subInput {
            color: gray;
        }
    }
}

@media screen and (max-width: 899px) {
    .login-create {
        flex-flow: column nowrap;
        width: 100vw;

        .forms {
            width: 90vw;
        }

        .third {
            width: 100vw;
            display: flex;
            flex-flow: column nowrap;
            order: 1;
            padding-bottom: 50px;
        }
        .has-brand {
            .vert-half-top {
                margin-top: 30px;
                top: 0;
                transform: translateY(0px);
                padding-bottom: 30px;
            }
            &.twoThird {
                order: 1
            }
        }

        .vert-half-top {
            top: 0;
            transform: translateY(0px);
            padding-bottom: 30px;
        }

        .twoThird {
            width: 100vw;
            order: 2;
            padding-top: 75px;
            padding-bottom: 50px;
        }

         // Hard overrides for the create mobile page
        &.create-account {
            .hidden-desktop {
                display: block;
            }
            .hidden-mobile {
                display: none;
            }
            .third {
                order: 2;
                flex: 1;
               
            }

            .twoThird {
                order: 1;
                
            }
        }
    }
}