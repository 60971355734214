.price-table {
    display: flex;
    flex-flow: column nowrap;
    // margin: auto;
    width: 100%;
    h3 {
        margin-top: 0px;
    }

    .cell {
        max-width: 100%;
        background: $brand-main;
        flex-flow: row nowrap;
        display: flex;
        border-bottom: 1px solid $brand-dark;
        color: $brand-v-light;
        box-shadow: $shadow;
        margin-bottom: 1rem;
        border: 1px solid $brand-dark;
        border-radius: 2px;
        cursor: pointer;
        &.active {
            background: $brand-accent;
        }

        .guests {
            margin-bottom: 0.8rem;
            padding: 0.8rem 1.5rem;
            padding-bottom: 0;
            flex: 1;

            > div:first-of-type {
                font-size: 18px;
            }
        }

        .price {
            display: flex;
            align-items: center;
            padding: 1rem 1.5rem;
            color: $brand-v-light;
            flex: 1;

            > div:first-of-type {
                font-size: 24px;
                margin-right: 5px;
            }
            a {
                color: white;
            }
        }
    }
}

.billing {
    width: 100%;
    position: relative;
    h3 {
        margin-top: 0px;
    }

    .c {
        position: absolute;
        right: -20px;
        top: -20px;
        color: $brand-dark;
        cursor: pointer;
    }
    .StripeElement {
        border-radius: 2px;
        width: 100%;
        border: 1px solid #bdbdbd;
        color: #333;
        padding: 0.4rem;
        font-size: 14px;
        box-sizing: border-box;
        &.clickable {
            cursor: pointer;
        }
        font-family: montserrat;
        font-weight: 300;
    }

    .afterPayment {
        text-align: center;
        svg,
        h3 {
            color: $brand-success;
        }
    }
}
