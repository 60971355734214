.reservationNav {
    font-size: 12px;
    width: 98%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    border: 1px solid $brand-dark;
    color: $brand-dark;
    border-radius: 4px;
    background-color: white;
    font-weight: 500;
    cursor: pointer;
    margin-bottom: 20px;
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    box-shadow: $shadow;
    z-index: 100;

    > div {
        text-align: center;
        padding: 0.8em 0.5em;

        // width: 33.33%;
        flex: 1;
        &:not(:last-of-type) {
            border-right: 1px solid $brand-dark;
        }
        &.active {
            background: $brand-dark;
            color: $brand-v-light;
        }
    }
}

@media screen and (max-width: 899px) {
    .reservationNav {
        // position: absolute;
        width: 99%;
    }
}
