$base-body-dark: #333333;
$base-body-light: #828282;
$base-body-v-light: #bdbdbd;
$body-background: #f4f4f4;

$brand-main: #457b9d;
$brand-light: #a8dadc;
$brand-dark: #1d3557;
$brand-v-light: #f1faee;
$brand-alert: #c55065;
$brand-success: #45ab7a;
$brand-accent: #f68963;
