.switch-container {
    display: flex;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    > :first-of-type {
        margin-right: 10px;
    }
    > :last-of-type {
        margin-left: 10px;
    }
    font-size: 16px;
    margin-bottom: 30px;
}

.plans {
    display: flex;
    > div {
        flex: 1 1;
        margin: 10px;
    }
}

.sub-title {
    margin-top: -15px;
    color: #515151;
}

.current-plan {
    margin-top: 30px;
    display: grid;
    grid-template-columns: 20% 75%;
    gap: 30px;
    .off-card-title {
        font-size: 18px;
        color: $brand-main;
        font-weight: bold;
        margin-bottom: 6px;
    }
    .off-card-extras {
        margin-top: 20px;
        display: flex;
        align-items: center;
        flex-flow: column nowrap;
        > a {
            display: flex;
            align-items: center;
            justify-content: center;
            box-sizing: border-box;
        }
        > button {
            margin-bottom: 10px;
        }
    }
}

.perks {
    text-align: center;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 400;
    color: #333;
}

@media screen and (max-width: 1350px) {
    .plans {
        display: grid;
        gap: 20px;
        grid-template-columns: repeat(3, 1fr);
        > *:last-of-type {
            margin-bottom: 30px;
        }
    }
}

@media screen and (max-width: 1099px) {
    .plans {
        display: grid;
        gap: 20px;
        grid-template-columns: repeat(2, 1fr);
        > *:last-of-type {
            margin-bottom: 30px;
        }
    }
    .current-plan {
        margin-top: 30px;
        display: grid;
        grid-template-columns: 1fr;
        gap: 30px;
        > *:last-of-type {
            margin-bottom: 30px;
        }
    }
}

@media screen and (max-width: 699px) {
    .plans {
        display: grid;
        gap: 20px;
        grid-template-columns: repeat(1, 1fr);
        > *:last-of-type {
            margin-bottom: 30px;
        }
    }
    .current-plan {
        margin-top: 30px;
        display: grid;
        grid-template-columns: 1fr;
        gap: 30px;
        > *:last-of-type {
            margin-bottom: 30px;
        }
    }
}
