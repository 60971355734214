.sub-limit {
    display: flex;
    .bar {
        flex: 1;
        .label {
            font-size: 24px;
            margin-bottom: 20px;
        }

        .progress-bar {
            height: 6px;
            background-color: #c8c8c8;
            border-radius: 12px;
            appearance: none;
            position: relative;
            width: 100%;
            margin: 10px 0px;
            box-shadow: inset 0px 0px 2px #0000001f;
            overflow: hidden;
            span {
                position: relative;
                background-color: $brand-success;
                display: block;
                height: 6px;
                border-radius: 12px;
            }
        }
    }
    .left {
        color: #535353;
        div:first-of-type {
            font-size: 42px;
        }
        div:last-of-type {
            font-size: 18px;
            align-self: flex-end;
        }
        width: 80px;
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-end;
        justify-content: center;
    }
    &.overage {
        color: $brand-alert;
        .bar {
            .progress-bar {
                span {
                    background-color: $brand-alert;
                }
            }
        }
        .left {
            color: $brand-alert;
        }
    }
}
