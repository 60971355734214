.service-form {
    * {
        transition: all .2s ease-in;
    }
    .details{
        margin-right: 20px;
        margin-bottom: 20px;
    }

    .published {
        padding-bottom: 15px;
        border-bottom: 1px solid #BDBDBD;
        margin-bottom: 20px;
    }

    .input-icon {
        position: absolute;
        right: .5rem;
        top: 2.3rem;
        color: #4F4F4F;
    }

    .form {
        h5 {
            color: #828282;
        }
    }
    .line {
        border-bottom: 1px solid #BDBDBD;
        margin-bottom: 20px;
        &.full-width {
            margin-right: -20px;
            margin-left: -20px;
        } 
    }

    .special-input {
        width: 100%;
        > div {
            border-radius: 2px;
            border: 1px solid #BDBDBD;
            padding: .20rem;
        }
    }
}