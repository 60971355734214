.page-container {
    margin: 0px $sideNav-margin;
}
.hidden-desktop {
    display: none;
}

.grid-wrapper {
    display: flex;
    &.row {
        flex-direction: row;
    }
    &.column {
        flex-direction: column;
    }
}
.grid {
    position: relative;
    &.w-10 {
        width: 10%;
    }
    &.w-25 {
        width: 25%;
    }
    &.w-35 {
        width: 35%;
    }
    &.w-45 {
        width: 45%;
    }
    &.w-50 {
        width: 50%;
    }
    &.w-70 {
        width: 25%;
    }
}

@media screen and (max-width: 900px) {
    .hidden-mobile {
        display: none;
    }
    .hidden-desktop {
        display: block;
    }
    .page-container {
        margin: 0px 15px;
    }

    .grid {
        &[class*="w-"] {
            width: 100%;
        }
    }
}