.interior-loader {
    display: flex;
    align-items: center;
    justify-content: center;

    .spinner {
        position: absolute;
        font-size: 21px;
        visibility: hidden;
        z-index: -1;
        opacity: 0;
        transition: all .2s ease-in-out;
    }
    .content {
        visibility: visible;
        z-index: 10;
        opacity: 1;
        transition: all .2s ease-in-out;
    }

    &.loading {
        .spinner {
            visibility: visible;
            opacity: 1;
            z-index: 10;
        }

        .content {
            visibility: hidden;
            opacity: 0;
            z-index: -1;
        }
    }
}