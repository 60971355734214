.form-control {
    border-radius: 2px;
    width: 100%;
    border: 1px solid #bdbdbd;
    color: #333;
    padding: 0.4rem;
    font-size: 14px;
    box-sizing: border-box;
    &.clickable {
        cursor: pointer;
    }
    font-family: montserrat;
    font-weight: 300;
    &:disabled {
        background: #dadada;
        cursor: no-drop;
    }
}

.icon-input {
    position: relative;
    .input-icon {
        position: absolute;
        left: 1px;
        top: 1px;
        color: #4f4f4f;
        padding: 7px 10px 6px 10px;
    }
    .form-control {
        padding-left: 35px;
    }
}

label {
    background: border-box;
    margin-bottom: 5px;
    display: inline-block;
    font-weight: 500;
    &.checkbox {
        display: flex;
        align-items: center;
        cursor: pointer;
    }
}

.input-with-button {
    display: flex;
    input.form-control {
        border-radius: 2px 0px 0px 2px;
        border-right: 0px;
    }
    button.btn {
        border-radius: 0px 2px 2px 0px;
    }
}

.form {
    width: 100%;

    .line {
        border-bottom: 1px solid #bdbdbd;
        margin-bottom: 20px;
        &.full-width {
            margin-right: -20px;
            margin-left: -20px;
        }
    }


    select.form-control {
        font-size: 14px;
        -webkit-appearance: none;
        background: white;
        &:disabled {
            background: #dadada;
            cursor: no-drop;
        }
    }

    input.checkbox {
        margin-right: 8px;
        display: inline-block;
        cursor: pointer;
    }

    img.thumbnail {
        border: 1px solid #ddd;
        border-radius: 4px;
        padding: 5px;
        max-width: 64px;
        width: auto;
        height: auto;
    }

    .form-section {
        margin-bottom: 1em;
        position: relative;
        width: 100%;
    }

    .inputfile {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }

    .inputfile + label {
        display: inline-block;
        cursor: pointer; /* "hand" cursor */
    }

    .inputfile:focus + label,
    .inputfile + label:hover {
        text-decoration: underline;
        color: $brand-main;
    }

    .title {
        margin-top: 20px;
        margin-bottom: 20px;
        &.only-bottom {
            margin-top: 0px;
        }
    }

    .help-text {
        padding-top: 4px;
        color: gray;
    }

    .field-error {
        padding-top: 4px;
        font-weight: 500;
        color: $brand-alert;
    }
}
