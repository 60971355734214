.serviceSettings {
    margin-top: 30px;

    h5.title {
        margin: 20px 0px 15px 0px;
    }

    h5.first {
        margin-top: 0px;
    }

    section > .item {
        display: flex;
        flex-flow: row nowrap;
        margin-bottom: 5px;

        > div {
            width: 140px;
            min-width: 140px;
            font-weight: 400;
        }
    }

    .delete-msg {
        padding-top: 4px;
        font-weight: 500;
    }

    .updateForm {
        position: fixed;
        top: 0px;
        right: -100px;
        transform: translate(100%, 0px);
        transition: all 0.2s ease-in-out;
        overflow-y: scroll;
        height: 100vh;
        padding: 20px;
        background: white;
        box-sizing: border-box;
        box-shadow: -1px 3px 6px #0000001f;
        width: 50%;
        z-index: 999;
        &.open {
            transition: all 0.2s ease-in-out;
            right: 0px;
            transform: translate(0px, 0px);
        }

        > div.card {
            height: 100%;
        }
    }
}
.service-link {
    position: relative;
    .input-icon {
        position: absolute;
        top: 39px;
        right: 10px;
        color: #404040;
    }
}

.service-reservations {
    position: relative;
    height: 100vh;
    overflow: hidden;
    margin-right: -30px;

    > div:first-of-type {
        margin-top: 20px;
    }

    .scrolly {
        max-height: 100vh;
        position: relative;
        overflow-y: scroll;
        width: 102%;

        &.scrolly-actions {
            overflow: visible;
            width: 100%;
        }
    }
    .serviceMap {
        margin-left: 2%;
        width: 78%;
        position: relative;
        overflow: scroll;
        box-shadow: inset 0 0 10px #0000001a;
        // background-image: url(https://firebasestorage.googleapis.com/v0/b/churchreserve-71a2f.appspot.com/o/sample%2Fgrid_panel.png?alt=media&token=5005ec69-27da-492b-ae16-bca390558095);
        // background-repeat: repeat;
        // z-index: -3;

        > * {
            position: absolute;
        }
        img {
            max-width: none;
        }
    }
}

.no-map-selected-title {
    margin: 20px;
    text-align: center;
    width: 100%;
    font-size: 24px;
}

.card-list {
    .filters {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 30px 0px;
        > div:first-of-type {
            width: 300px;
        }
    }

    .headers {
        display: flex;
        margin-bottom: 15px;
        font-weight: 500;
        color: #828282;
        padding: 0px 10px;
    }

    .items {
        display: flex;
        flex-flow: column nowrap;
        .item-card {
            box-sizing: border-box;
            width: 100%;
            display: flex;
            margin-bottom: 2px;
            align-items: center;
        }

        .badge {
            font-size: 10px;
            padding: 0.25rem 0.5rem;
            background: #676767;
            border-radius: 2px;
            text-align: center;
            color: $brand-v-light;
            &.accent {
                background: $brand-accent;
            }
        }
    }

    .item-card,
    .headers {
        > div:not(:last-of-type) {
            width: 24%;
            margin: 0 5px;
        }
        > div:last-of-type {
            position: relative;
            width: 215px;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            button {
                margin-right: 10px;
                white-space: nowrap;
            }
        }
    }
}

.seat-numbber-toggle {
    z-index: 999;
    border-radius: 50px;
    border: 1px solid #c4c4c4;
    background: #ffffffe3;
    margin-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    top: 10px;
    right: 100px;
    padding: 10px;
    label {
        margin: 0px;
    }
    .seat-number-toggle-switch.react-toggle--checked .react-toggle-track {
        background: $brand-success;
    }
    > * {
        cursor: pointer;
    }
}

@media screen and (max-width: 899px) {
    .service {
        .serviceSettings {
            .updateForm {
                width: 100vw;
            }
        }
        .service-reservations {
            margin: auto;
            flex-direction: column;
            height: auto;
            overflow: visible;

            .scrolly {
                width: 100%;
                overflow: visible;
                height: 230px;
                &.scrolly-actions {
                    height: 120px;
                }
            }

            &.edit-seating {
                .scrolly {
                    &.scrolly-actions {
                        height: 170px;
                    }
                }
            }

            .serviceMap {
                width: 100vw;
                top: 0px;
                height: 100vh;
                margin: 0 0 0 -15px;
                
            }
        }

        .card-list {
            .headers {
                display: none;
            }
            .items {
                margin-top: 15px;
            }
            .item-card {
                display: flex;
                flex-flow: column wrap;
                grid-template-columns: 1fr 1fr;
                grid-template-areas:
                    'name'
                    'guests'
                    'email'
                    'phone'
                    'extras';
                > div {
                    margin-bottom: 8px;
                    width: 100%;
                }
                .name {
                    grid-area: name;
                    font-size: 16px;
                    font-weight: bold;
                }
                .seats {
                    grid-area: guests;
                }
                .email {
                    grid-area: email;
                }
                .phone {
                    grid-area: phone;
                }

                .extras {
                    grid-area: extras;
                    display: flex;
                    justify-content: flex-start;
                    margin-bottom: 0px;
                    button {
                        margin-right: 0px;
                    }
                    > div {
                        margin-bottom: 10px;
                    }
                }
            }
            .filters {
                flex-flow: column;
                align-items: flex-start;
                > div {
                    margin-bottom: 10px;
                }
                .icon-input {
                    width: 100%;
                }
            }
        }
    }
}
