.table {
    font-size: 14px;
    width: 100%;
    th {
        font-weight: 400;
        text-align: left;
        border-bottom: 2px solid #BDBDBD;
        padding: 0px 15px 15px 15px;
    }
    th:first-of-type {
        padding: 0px 15px 15px 0px;
    }

    td {
        vertical-align: middle;
        padding: 15px 15px;
        border-bottom: 1px solid #BDBDBD

    }
    td:first-of-type {
        padding: 15px 15px 15px 0px;
    }
    
    tr:last-of-type {
        td {
            padding-bottom: 0px;
            border-width: 0px;
        }
    }
    .with-avatar {
        display: flex;
        align-items: center;
    }

    &.sortable {
        tr {
            cursor: pointer;
        }
    }
}