.loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: $brand-main;
    display: flex;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001;
    visibility: visible;
    opacity: 1;
    
    &.hide {
        opacity: 0;
        visibility: hidden;
        animation: fadeOut .5s;        
    }
    img {
        max-width: 200px;
    }
}

@keyframes fadeOut{
    0% {
        visibility: visible;
        opacity: 1;
        display: flex;
    }
    99% {
        visibility: hidden;
        opacity: 0;
        display: flex;
    }
    100% {
        visibility: hidden;
        opacity: 0;
        display: none;
    }
}