.avatar {
   border-radius: 50%;
   width: 40px;
   height: 40px;
   margin-right: 8px;
   display: inline-block;
   img {
      width: 40px;
      height: 40px;
      border-radius: 100%;
   }
}