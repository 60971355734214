.card {
    background: white;
    padding: 20px;
    box-shadow: $shadow;

    &.padding-15-vert {
        padding: 15px 20px;
    }
    &.padding-small {
        padding: 15px;
    }

    &.margin-bottom {
        margin-bottom: 30px;
    }
}