@charset "utf-8";

@import './variables/colors';
@import './variables/vars';
@import './reset';

/*** Blocks ***/
@import './blocks/subNav.scss';
@import './blocks/card.scss';
@import './blocks/table.scss';
@import './blocks/button.scss';
@import './blocks/avatar.scss';
@import './blocks/text.scss';
@import './blocks/grid.scss';
@import './blocks/serviceCard.scss';
@import './blocks/form.scss';
@import './blocks/serviceHeader.scss';
@import './blocks/alert.scss';
@import './blocks/reservationsNav.scss';
@import './blocks/reservationsList.scss';
@import './blocks/modal.scss';
@import './blocks/loading.scss';
@import './blocks/interiorLoader.scss';
@import './blocks/serviceForm.scss';
@import './blocks/paginate.scss';
@import './blocks/subscriptionCard.scss';
@import './blocks/subscriptionLimitsCard.scss';

/*** Screens **/
@import '../screens/Sidebar/Sidebar.scss';
@import '../screens/Services/Services.scss';
@import '../screens/Service/Service.scss';
@import '../screens/Auth/Auth.scss';
@import '../screens/Payment/Payment.scss';
@import '../screens/Billing/Billing.scss';

body {

  .react-confirm-alert-overlay {
    z-index: 9999;
    .react-confirm-alert-body {
      width: auto !important;
    }
  }
}
