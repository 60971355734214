.subscription-card {

    .plan-title {
        font-size: 24px;
        color: $brand-dark;
        text-align: center;
    }
    .desc {
        text-align: center;
        color: $brand-main;
    }

    .price {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        .line {
            width: 20%;
            border-top: 2px solid $brand-accent;
            margin: 20px 0px;
        }
        .number {
            display: flex;
            color: $brand-dark;
            > :first-of-type {
                font-size: 44px;
            }
            .digits {
                align-self: flex-start;
                font-size: 18px;
                margin-top: 7px;
                margin-left: 3px;
            }
            .month {
                align-self: flex-end;
                font-size: 14px;
                margin-bottom: 12px;
            }
        }
        button {
            margin-top: 15px;
        }
    }
    .perks {
        ul {
            list-style-type: disc;
            text-align: left;
            li {
                margin-left: 50px;

                &:not(:last-of-type) {
                    margin-bottom: 10px;
                }
                svg {
                    margin-left: -22px;
                    margin-right: 10px;
                }
            }
        }
    }
}
