.subNav {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    background: white;
    box-shadow: $box-shadow;
    margin-bottom: 30px;
    z-index: 3;
    position: relative;
    a {
        &:first-of-type {
            margin-left: $sideNav-margin;
        }
        color: $brand-dark;
        text-decoration: none;
        padding: 0.85em 1.5em;
        &:hover,
        &:visited {
            color: $brand-dark;
        }
        border-bottom: 4px solid white;
        &.topNav-active {
            border-bottom: 4px solid $brand-dark;
        }
    }
    .signOut {
        position: absolute;
        right: 15px;
    }
}

@media screen and (max-width: 899px) {
    .subNav {
        overflow-x: scroll;
        a {
            &:first-of-type {
                margin-left: 0px;
            }
            text-align: center;
            white-space: nowrap;
        }
    }
}
