$menuWidth: 230px;
$shrunkMenu: 65px;
$containerSlide: all 0.2s ease-out;

.sidebar {
    box-sizing: border-box;
    width: $menuWidth;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 2;
    transition: $containerSlide;
    background-color: $brand-main;
    color: $brand-v-light;
    display: flex;
    flex-flow: column nowrap;
    overflow: hidden;
    box-shadow: 4px 0px 10px #00000054;

    .sidebar-container {
        padding: 20px 0;
        font-size: 16px;
        display: flex;
        flex-flow: row nowrap;
        overflow: hidden;
        display: flex;
        flex-flow: column nowrap;
        height: 100%;

        nav {
            display: flex;
            flex-flow: row nowrap;

            .nav-menu {
                width: 100%;
                li {
                    padding: 12px 20px 12px 0px;
                    opacity: 0.8;

                    &.trial-countdown {
                        margin-top: 10px;
                        padding: 10px;
                        text-align: center;
                        font-size: 14px;
                        display: flex;
                        flex-flow: column nowrap;
                        button {
                            margin-top: 10px;
                        }
                    }
                }
                ul {
                    width: $menuWidth;
                    li {
                        display: flex;
                        flex-flow: row nowrap;
                        align-items: center;
                        .text {
                            white-space: nowrap;
                        }
                    }
                    .icon {
                        text-align: center;
                        padding-left: 10px;
                        padding-right: 10px;
                        width: 45px;
                    }
                    a {
                        color: $brand-v-light;
                        text-decoration: none;
                        &:visited {
                            color: $brand-v-light;
                        }
                        &.sidebar-active > * {
                            background: $brand-dark;
                            opacity: 1;
                        }
                    }
                }
            }
            .top {
                position: absolute;
            }
            .bottom {
                display: flex;
                flex-flow: row nowrap;
                position: absolute;
                bottom: 20px;
            }

            .divider {
                border-top: 1px solid white;
                opacity: 0.2;
                margin: 20px 20px;
            }
        }

        .nav-toggle {
            position: absolute;
            bottom: 30px;
            padding: 15px 10px 15px 9px;
            margin-left: 2px;
            width: 205px;
            .icon {
                display: flex;
                align-items: center;
                background: #ffffff45;
                padding: 10px;
                border-radius: 2px;
                cursor: pointer;
                > svg {
                    margin-right: 20px;
                    margin-left: 2px;
                }
            }
        }
    }
}
.main {
    transition: $containerSlide;
    position: relative;
    margin-left: $menuWidth;
    top: 0;
}

.logo {
    text-align: center;
    height: 98px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    > img {
        width: 138px;
    }
}

.churchreserve.menu-shrunk {
    .sidebar {
        width: $shrunkMenu !important;
        .nav-toggle .icon {
            width: 21px;
            > div {
                display: none;
            }
            svg {
                transform: rotate(180deg);
            }
        }
        .divider {
            margin: 20px 10px;
            width: 41px;
        }
        .logo img {
            align-self: flex-start;
            width: 70px;
        }
    }
    .main {
        margin-left: $shrunkMenu !important;
    }
}

.main header {
    background-color: $brand-main;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;

    .hamburger {
        width: 30px;
        height: 38px;
        div {
            transition: 0.25s ease-in;
            height: 2px;
            background-color: $brand-v-light;
            border-radius: 5px;
            margin: 8px 0px;
        }
        div:first-of-type {
            width: 52%;
        }
        div:last-of-type {
            width: 52%;
            position: relative;
            left: 48%;
        }
    }
}

@media screen and (max-width: 899px) {
    .sidebar {
        // transform: translate3d(-$menuWidth, 0, 0);
        left: -$menuWidth;
        box-shadow: none;
    }
    .main {
        // transform: translate3d(0px, 0, 0);
        left: 0px;
        margin-left: 0;
    }

    .nav-toggle {
        display: none;
    }

    .churchreserve.menu-open {
        .sidebar {
            transform: translate3d(0px, 0, 0);
            left: 0px;
        }
        .main {
            // transform: translate3d($menuWidth, 0, 0);
            left: $menuWidth;
            .hamburger {
                div:first-of-type {
                    transform: rotate(45deg);
                    width: 100%;
                    position: relative;
                    top: 6px;
                    left: 0px;
                }
                div:nth-child(2) {
                    display: none;
                }
                div:last-of-type {
                    transform: rotate(-45deg);
                    width: 100%;
                    left: 0px;
                    top: -4px;
                }
            }
        }
    }
}

@media screen and (min-width: 900px) {
    .main header,
    .hamburger {
        display: none;
    }
}
