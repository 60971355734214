.btn {
    border-radius: 2px;
    font-size: 1rem;
    font-weight: 300;
    font-size: 14px;
    border-width: 0px;
    cursor: pointer;
    &:focus {
        outline: #00000042 auto 1px;
    }

    &:disabled {
        opacity: 0.75;
        cursor: not-allowed;
    }

    &:not(:last-of-type) {
        margin-right: 5px;
    }

    &.withIcon,
    &.withIcon .content {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    border: 1px solid #4f4f4f;
    background: #4f4f4f;
    color: white;
    height: 2.5em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-right: 1.25em;
    padding-left: 1.25em;

    &.block {
        width: 100%;
    }

    &.big {
        line-height: 1;
        font-size: 20px;
        padding-top: 1em;
        padding-bottom: 1em;
        padding-right: 1.75em;
        padding-left: 1.75em;
        height: 1em;
    }

    &:active {
        background-color: darken($color: #4f4f4f, $amount: 15);
    }
    &.outline {
        background-color: transparent;
        color: #4f4f4f;
        &:active {
            background-color: lighten($color: #4f4f4f, $amount: 40);
        }
    }

    &.white {
        background-color: white;
        color: $brand-dark;
        border: 1px solid white;
        &:active {
            background-color: darken($color: white, $amount: 15);
        }
        &.outline {
            background-color: transparent;
            color: white;
            &:active {
                background-color: lighten($color: white, $amount: 40);
            }
        }
    }

    &.success {
        background-color: $brand-success;
        color: $brand-v-light;
        border: 1px solid $brand-success;
        &:active {
            background-color: darken($color: $brand-success, $amount: 15);
        }
        &.outline {
            background-color: transparent;
            color: $brand-success;
            &:active {
                background-color: lighten($color: $brand-success, $amount: 40);
            }
        }
    }

    &.primary {
        background-color: $brand-main;
        color: $brand-v-light;
        border: 1px solid $brand-main;
        &:active {
            background-color: darken($color: $brand-main, $amount: 15);
        }
        &.outline {
            background-color: transparent;
            color: $brand-main;
            &:active {
                background-color: lighten($color: $brand-main, $amount: 40);
            }
        }
    }

    &.accent {
        background-color: $brand-accent;
        color: $brand-v-light;
        border: 1px solid $brand-accent;
        &:active {
            background-color: darken($color: $brand-accent, $amount: 15);
        }
        &.outline {
            background-color: transparent;
            color: $brand-accent;
            &:active {
                background-color: lighten($color: $brand-accent, $amount: 40);
            }
        }
    }

    &.alert {
        background-color: $brand-alert;
        color: $brand-v-light;
        border: 1px solid $brand-alert;
        &:active {
            background-color: darken($color: $brand-alert, $amount: 15);
        }
        &.outline {
            background-color: transparent;
            color: $brand-alert;
            &:active {
                background-color: lighten($color: $brand-alert, $amount: 40);
            }
        }
    }

    &.dark {
        background-color: $brand-dark;
        color: white;
        border: 1px solid $brand-dark;
        &:active {
            background-color: darken($color: $brand-dark, $amount: 15);
        }
        &.outline {
            background-color: transparent;
            color: $brand-dark;
            &:active {
                background-color: lighten($color: $brand-dark, $amount: 40);
            }
        }
    }

    &.link {
        background-color: transparent;
        color: $brand-main;
        border: 0px;
        text-decoration: underline;
        &:active {
            background-color: transparent;
        }
    }
}
