.reservations-list {
    max-height: 100vh;
    display: flex;
    flex-flow: column nowrap;
    width: 99%;

    .reservation {
        user-select: none;
        background-color: white;
        box-shadow: $shadow;
        margin-bottom: 15px;
        border: 2px solid white;
        transition: all 0.1s ease-in;
        &.active {
            border: 2px solid $brand-accent;
        }
        .top {
            padding: 10px;
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #e0e0e0;
            .guest {
                display: flex;
                flex-direction: column;
                flex: 1;
                .seats {
                    color: #333;
                    display: flex;
                    flex-flow: row wrap;
                    > div {
                        width: auto;
                        height: 20px;
                        background: #e2e2e2;
                        text-align: center;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 40px;
                        margin: 2px 1px 0px 1px;
                        font-size: 12px;
                        padding: 0px 5px;
                        &:first-of-type {
                            margin-left: 0px;
                        }
                        &:last-of-type {
                            margin-right: 0px;
                        }
                    }
                }
            }

            .count {
                padding: 5px;
                border-radius: 50%;
                color: $brand-main;
                border: 2px solid $brand-main;
                width: 1.15em;
                height: 1.15em;
                font-size: 16px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        .btns {
            // padding: 15px 0;
            display: flex;
            justify-content: space-between;
            cursor: pointer;
            > div {
                padding: 15px;
                width: 40%;
                text-align: center;
                &:hover {
                    background: #f3f3f3;
                }
            }
            .confirmation {
                background: lighten($brand-accent, 25);
                &:hover {
                    background: $brand-accent;
                }
            }
        }
    }
}

.reservations-actions-list {
    position: relative;
    width: 100%;
    margin: 0 auto;

    .actions {
        position: relative;
        perspective: 1500px;
    }

    .slide-out {
        transform: rotateX(180deg);
    }

    .action-section {
        transition: transform 0.3s ease-in;
        transform-style: preserve-3d;
        width: 100%;
        position: relative;
        height: 60px;
        margin-bottom: 10px;
    }

    .action-item {
        font-size: 16px;
        background: white;
        box-shadow: $shadow;
        margin-bottom: 15px;
        cursor: pointer;
        border-radius: 2px;
        height: 100%;
        display: flex;
        > div {
            margin-left: 20px;
        }

        &.active {
            background: $brand-accent;
        }
        .grid-wrapper {
            align-items: center;
            .text {
                margin-left: 8px;
            }
        }

        &.back {
            transform: rotateX(180deg);
            border: 2px solid $brand-accent;
        }

        &.front,
        &.back {
            position: absolute;
            width: 100%;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
        }
    }

    .seats {
        display: flex;
        flex-flow: column nowrap;
        // align-items: center;

        button {
            margin-bottom: 15px;
            border-radius: 0px;
            height: 4em;
            width: 8em;
        }
    }
}

@media screen and (max-width: 899px) {
    .reservations-list {
        flex-flow: row nowrap;
        position: relative;
        display: flex;
        overflow: scroll;
        // top: 60px;
        scroll-snap-type: x mandatory;

        .reservation {
            width: 350px;
            margin-right: 15px;
            min-width: 320px;
            scroll-snap-align: start;
        }
    }
}
