.alert-success {
    padding: 15px;
    background-color: $brand-success;
    color: white;
    margin-bottom: 20px;
    border-radius: 2px;
    border: 1px solid darken($brand-success, 8);
    a {
        text-decoration: underline;
        color: white, 15;
        font-weight: 500;
    }
}

.alert-danger {
    padding: 15px;
    background-color: $brand-alert;
    color: white;
    margin-bottom: 20px;
    border-radius: 2px;
    border: 1px solid darken($brand-alert, 8);
    a {
        text-decoration: underline;
        color: #ffdddf;
        font-weight: 500;
    }
}

.alert-dark {
    padding: 15px;
    background-color: $brand-dark;
    color: white;
    margin-bottom: 20px;
    border-radius: 2px;
    border: 1px solid darken($brand-dark, 8);
    a {
        text-decoration: underline;
        color: white;
        font-weight: 500;
    }
}

.alert-white {
    padding: 15px;
    color: $brand-dark;
    border: 1px solid $brand-dark;
    margin-bottom: 20px;
    border-radius: 2px;
    a {
        text-decoration: underline;
        color: $brand-dark;
        font-weight: 500;
    }
}

.alert-accent {
    padding: 15px;
    color: white;
    background-color: $brand-accent;
    margin-bottom: 20px;
    border-radius: 2px;
    border: 1px solid darken($brand-accent, 8);
    a {
        text-decoration: underline;
        color: white;
        font-weight: 500;
    }
}
