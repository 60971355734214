.text-main {
    color: $brand-main;
}
.text-dark {
    color: $brand-dark;
}
.text-success {
    color: $brand-success;
}
.text-light-gray {
    color: #828282;
}
.text-alert {
    color: $brand-alert;
}

.text-normal {
    font-weight: 300;
}

.text-center {
    text-align: center;
}
p {
    margin-bottom: .8rem;
}

.margin-bottom {
    margin-bottom: 1em;
}

.text-capitalize {
    text-transform: capitalize;
}